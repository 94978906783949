import { NotificationPing } from '@/components/ui';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { Process } from '@/lib/types';
import {
    cn,
    getFirstName,
    isNewProcess,
    isOngoingEvent,
    isPendingFeedback,
} from '@/lib/utils';
import GenericProcessActionsView from '@/processes/actions/GenericProcessActionsView';
import PostEventFeedbackActionsView from '@/processes/actions/PostEventFeedbackActionsView';
import NewProcessActionsViewProvider from '@/processes/actions/newProcesses/NewProcessActionsViewProvider';
import { dialogs as dialogStrings } from '@/strings';

const strings = dialogStrings.candidateDetails;

enum ProcessActionsViewType {
    New = 'new',
    OngoingEvent = 'ongoing_event',
    PendingEventFeedback = 'post_event_feedback',
    Default = 'default',
}

const getActionsViewType = (process: Process): ProcessActionsViewType => {
    if (isNewProcess(process)) {
        return ProcessActionsViewType.New;
    }
    if (isPendingFeedback(process)) {
        return ProcessActionsViewType.PendingEventFeedback;
    }
    if (isOngoingEvent(process)) {
        return ProcessActionsViewType.OngoingEvent;
    }
    return ProcessActionsViewType.Default;
};

export function ProcessCardActionsView({ process }: { process: Process }) {
    const isMobile = useIsMobileScreen();
    const viewType = getActionsViewType(process);
    const showPostEventActions = [
        ProcessActionsViewType.PendingEventFeedback,
        ProcessActionsViewType.OngoingEvent,
    ].includes(viewType);
    const isPendingPostEventActions =
        viewType === ProcessActionsViewType.PendingEventFeedback;

    const firstName = getFirstName(process.candidate);
    return (
        <div
            className={cn('py-8 px-10', {
                'px-8': showPostEventActions && !isMobile,
                'p-6': showPostEventActions && isMobile,
            })}
        >
            {showPostEventActions && (
                <>
                    {isMobile && (
                        <div className={cn('flex w-full justify-center')}>
                            <div
                                className={cn(
                                    'w-full max-w-[400px] flex flex-col gap-5',
                                )}
                            >
                                {isPendingPostEventActions && (
                                    <div className={cn('flex items-center gap-3')}>
                                        <NotificationPing className={cn('shrink-0')} />
                                        <div className={cn('text-12 font-medium')}>
                                            {strings.pendingFeedback.shortNote.func(
                                                firstName,
                                            )}
                                        </div>
                                    </div>
                                )}
                                <PostEventFeedbackActionsView
                                    process={process}
                                    context={'process_card'}
                                    isVertical
                                />
                            </div>
                        </div>
                    )}
                    {!isMobile && (
                        <div
                            className={cn(
                                'flex w-full justify-between items-center gap-2',
                            )}
                        >
                            {isPendingPostEventActions ? (
                                <div className={cn('flex items-center gap-3')}>
                                    <NotificationPing className={cn('shrink-0')} />
                                    <div className={cn('text-14 font-medium')}>
                                        {strings.pendingFeedback.note.func(
                                            firstName,
                                            process.status,
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div />
                            )}
                            <PostEventFeedbackActionsView
                                process={process}
                                context={'process_card'}
                            />
                        </div>
                    )}
                </>
            )}
            {viewType === ProcessActionsViewType.New && (
                <NewProcessActionsViewProvider
                    process={process}
                    containerProps={{
                        className: cn('xs:w-full xs:max-w-[550px] justify-end'),
                    }}
                    rejectButtonProps={{ className: cn('w-[98px] xs:w-full') }}
                    approveButtonProps={{ className: cn('xs:w-full') }}
                />
            )}
            {viewType === ProcessActionsViewType.Default && (
                <GenericProcessActionsView process={process} />
            )}
        </div>
    );
}

export default ProcessCardActionsView;
