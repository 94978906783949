import { CustomPopover, PopoverMenu, SelectionButton } from '@/components/ui';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { process as strings } from '@/strings';
import { useState } from 'react';

import { GenericProcessActionsViewProps } from './types';
import { useProcessActionMenuItems } from './useAllowedProcessActions';

export function GenericProcessActionsView({
    rootProps,
    process,
}: GenericProcessActionsViewProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuItems = useProcessActionMenuItems(process);
    return (
        <div
            {...rootProps}
            className={cn(
                'flex w-full items-center justify-end xs:justify-center',
                rootProps?.className,
            )}
        >
            <CustomPopover
                trigger={
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className={cn('xs:w-full xs:max-w-[400px]')}
                    >
                        <SelectionButton
                            variant={'outlinedAction'}
                            open={isMenuOpen}
                            label={strings.actions.menuLabels.generic}
                            onClick={() =>
                                trackEvent(MixpanelEvent.ProcessCardOpenActionsMenu, {
                                    candidate_id: process.candidate.id,
                                    candidate_name: process.candidate.name,
                                })
                            }
                            className={cn('xs:w-full text-13 h-14')}
                        />
                    </div>
                }
                triggerProps={{ asChild: true }}
                open={isMenuOpen}
                setOpen={setIsMenuOpen}
                contentProps={{
                    variant: 'noPointer',
                    align: 'end',
                    alignOffset: 0,
                    sideOffset: 6,
                }}
            >
                <PopoverMenu
                    items={menuItems}
                    itemsProps={{
                        rootProps: {
                            className: cn('h-14 xs:h-[64px]'),
                        },
                    }}
                />
            </CustomPopover>
        </div>
    );
}

export default GenericProcessActionsView;
