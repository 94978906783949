import { InProcessFilter } from '@/lib/types';
import {
    dateToDayInMonthShort,
    dateToLongMonthName,
    oneLineFacilities,
    professionWithExp,
} from '@/strings/common';

const strings = {
    tabLabel: 'In process',
    noCandidtes: 'No in-process candidates',
    showAllFilter: {
        func: (count: number) => `All (${count})`,
        example: [11],
    },
    substatusFilters: {
        [InProcessFilter.PendingAction]: {
            func: (count: number) => `Pending action (${count})`,
            example: [5],
        },
        [InProcessFilter.Upcoming]: {
            func: (count: number) => `Upcoming events (${count})`,
            example: [1],
        },
        [InProcessFilter.InProgress]: {
            func: (count: number) => `In process (${count})`,
            example: [12],
        },
        [InProcessFilter.Paperwork]: {
            func: (count: number) => `Offer (${count})`,
            example: [12],
        },
        [InProcessFilter.InterviewRequest]: {
            func: (count: number) => `Interview requests (${count})`,
            example: [12],
        },
    },
    statuses: {
        phoneInterview: 'Phone Interview',
        inPersonInterview: 'In-Person Interview',
        paperwork: 'Offer (Docs & Screening)',
        orientation: 'Orientation',
        inProcess: 'Candidate In Process',
        terminated: 'Rejected',
    },
    substatusesDescription: {
        interviewRequestSent: 'Interview request sent',
        orientationRequestSent: 'Request sent',
        pendingRecruiterAciton: 'Pending your action',
        upcomingEvent: {
            func: (startAt: Date) =>
                `Scheduled for ${dateToLongMonthName(startAt)} ${dateToDayInMonthShort(startAt)}`,
            example: [new Date('2024-05-07T12:00:00')],
        },
        upcomingEventPast: {
            func: (startAt: Date) =>
                `Scheduled for ${dateToLongMonthName(startAt)} ${dateToDayInMonthShort(startAt)}`,
            example: [new Date('2022-01-01T12:00:00')],
        },
    },
    processBlock: {
        professionWithExp,
        oneLineFacilities,
    },
};

export default strings;
