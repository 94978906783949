import { Select } from '@/components/ui';
import { Time } from '@/lib/base';
import { cn } from '@/lib/utils';
import { timeToStringLong12, timeToStringShort12 } from '@/strings/common';

import { TIME_INTERVAL_ROW_HEIGHT_CLASS } from './common';
import { TimeInputProps } from './types';

function TimeInput({ time, onChange, hasError, selectableTimes }: TimeInputProps) {
    return (
        <div>
            <Select
                triggerProps={{
                    className: cn(
                        'flex items-center justify-center',
                        'rounded-[8px] bg-white border border-neutral300',
                        'w-[88px] p-0',
                        'text-14 font-medium text-black',
                        TIME_INTERVAL_ROW_HEIGHT_CLASS,
                        { 'border-red200 text-red200': hasError },
                    ),
                }}
                value={time.toString()}
                onChange={(v) => {
                    onChange(Time.createFromString(v));
                }}
                options={selectableTimes.map((t) => ({
                    value: t.toString(),
                    label: timeToStringLong12(t).replace(' ', '').toLowerCase(),
                    id: t.toString(),
                }))}
                customValueLabel={(timeString) =>
                    timeToStringShort12(Time.createFromString(timeString))
                        .replace(' ', '')
                        .toLowerCase()
                }
                contentProps={{
                    className: cn('max-h-[358px]'),
                }}
                mobileContentProps={{
                    rootProps: { className: 'max-h-[400px]' },
                }}
                popoverStyle
                scrollToSelectedOption
            />
        </div>
    );
}

export default TimeInput;
