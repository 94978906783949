import {
    CandidateProfileStatus,
    EventStatus,
    EventType,
    ProcessStatus,
    ProcessSubstatus,
    RecruiterApprovalStatus,
    ResumeStatus,
} from '@/lib/api/v1';
import { Candidate, FlatCandidateEvent, Process, Profession } from '@/lib/types';

export const exampleFlatProcessEvent: FlatCandidateEvent = {
    id: '1',
    candidateId: '1',
    allDay: false,
    startAt: new Date('2024-03-08T12:00:00Z'),
    endAt: new Date('2024-03-08T13:00:00Z'),
    canceled: false,
    status: EventStatus.Scheduled,
    eventType: EventType.PhoneInterview,
    processId: '1',
};

export const exampleCandidate: Candidate = {
    id: '1',
    name: 'Candy Deight',
    email: 'Candy@deight.org',
    phone: '+1234567890',
    address: 'Yermiyahu 24, Tel Aviv',
    profession: Profession.RN,
    hourlySalaryFlexible: false,
    createdAt: '2024-03-08T12:00:00Z',
    hasResume: true,
    resumeStatus: ResumeStatus.Ready,
    profileStatus: CandidateProfileStatus.Verified,
};

export const exampleProcess: Process = {
    id: '1',
    candidateId: '1',
    organizationId: '1',
    batchId: '1',
    candidateInterested: true,
    recruiterApproved: true,
    recruiterApprovalStatus: RecruiterApprovalStatus.Approved,
    status: ProcessStatus.PhoneInterview,
    substatus: ProcessSubstatus.Done,
    candidate: exampleCandidate,
    recruiter: {} as Process['recruiter'],
    organization: {} as Process['organization'],
    jobMatches: [],
    actions: [],
    candidateInterestedAt: new Date('2024-03-08T12:00:00Z'),
    statusUpdatedAt: new Date('2024-03-08T12:00:00Z'),
    createdAt: new Date('2024-03-08T12:00:00Z'),
    updatedAt: new Date('2024-03-08T12:00:00Z'),
    minCommuteDuration: null,
};
