import { ProcessStatus } from '@/lib/api/v1';

import { processStatusToEventString } from '../common';

const strings = {
    actionsLabel: 'Actions',
    processStatusLabel: 'Status',
    pendingFeedback: {
        shortNote: {
            func: (firstName: string) =>
                `Please determine the next step for ${firstName}.`,
            example: ['Candy'],
        },
        note: {
            func: (firstName: string, processStatus: ProcessStatus) =>
                `How was ${firstName}'s ${processStatusToEventString(processStatus) ?? ''}? Please determine
                the next step.`,
            examples: [
                ['Candy', ProcessStatus.PhoneInterview],
                ['Candy', ProcessStatus.InPersonInterview],
                ['Candy', ProcessStatus.Orientation],
            ],
        },
    },
};

export default strings;
