import {
    AlertIcon,
    CalendarIcon,
    OfferIcon,
    OrientationIcon,
    RescheduleIcon,
    TerminateIcon,
} from '@/components/svgs';
import { IconWithLabelProps } from '@/components/ui';
import { ProcessStatus } from '@/lib/api/v1';
import { sortEnumList } from '@/lib/base';
import { Process, ProcessActionButton } from '@/lib/types';
import { process as processStrings } from '@/strings';
import { keyBy, map } from 'lodash';

import { ProcessActionMenuItem } from './types';

const strings = processStrings.actions;

const processActionButtonsOrdered = [
    ProcessActionButton.RequestAnotherInterview,
    ProcessActionButton.MoveToOffer,
    ProcessActionButton.MarkHired,
    ProcessActionButton.CandidateNoShow,
    ProcessActionButton.RecruiterNoShow,
    ProcessActionButton.Reschedule,
    ProcessActionButton.Terminate,
];

export const getSortedProcessActionItems = (
    items: ProcessActionMenuItem[],
): ProcessActionMenuItem[] => {
    const itemIds = map(items, 'id');
    const itemsById = keyBy(items, 'id');
    const itemIdsSorted = sortEnumList(itemIds, processActionButtonsOrdered);
    return itemIdsSorted.map((id) => itemsById[id]);
};

export function getLabelAndIcon(
    action: ProcessActionButton,
    process: Process,
): Pick<IconWithLabelProps, 'Icon' | 'label'> {
    switch (action) {
        case ProcessActionButton.MarkHired:
            return {
                label: strings.markHired,
                Icon: OrientationIcon,
            };
        case ProcessActionButton.MoveToOffer:
            return {
                label: strings.moveToOffer,
                Icon: OfferIcon,
            };
        case ProcessActionButton.RequestAnotherInterview:
            return {
                label: strings.requestAnotherInterview,
                Icon: CalendarIcon,
            };
        case ProcessActionButton.Reschedule:
            return {
                label: strings.reschedule,
                Icon: RescheduleIcon,
            };
        case ProcessActionButton.Terminate:
            return {
                label: strings.terminate,
                Icon: TerminateIcon,
            };
        case ProcessActionButton.RecruiterNoShow:
            return {
                label:
                    process.status === ProcessStatus.Orientation
                        ? strings.recruiterNoShow.orientation
                        : strings.recruiterNoShow.interview,
                Icon: AlertIcon,
            };
        case ProcessActionButton.CandidateNoShow:
            return {
                label: strings.candidateNoShow,
                Icon: AlertIcon,
            };
        default: {
            const exhaustiveCheck: never = action;
            throw new Error(`Unhandled action item: ${exhaustiveCheck}`);
        }
    }
}
