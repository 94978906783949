export enum DialogName {
    CandidateNoShow = 'CandidateNoShowConfirm',
    RecruiterNoShowConfirm = 'RecruiterNoShowConfirm',
    RequestAnotherInterviewConfirm = 'RequestAnotherInterviewConfirm',
    MarkHiredConfirm = 'MarkHiredConfirm',
    RecruiterAvailability = 'RecruiterAvailability',
    RejectCandidateConfirm = 'RejectCandidateConfirm',
    WeeklyAvailabilityMissing = 'WeeklyAvailabilityMissing',
    CandidateDetails = 'CandidateDetails',
    RescheduleEventConfirm = 'RescheduleEventConfirm',
    TerminateProcess = 'TerminateProcess',
    MoveToOfferConfirm = 'MoveToOfferConfirm',
    EventDetails = 'EventDetails',
}

export type DialogCallback = () => unknown;

export interface DialogCallbackConfig {
    callback: DialogCallback;
    dialog: DialogName;
}
