import { setDialogOpen } from '@/_reducers/dialogs';
import { PatchProcessStatus, updateProcessStatus } from '@/_reducers/processes';
import {
    IsRequestAnotherInterviewConfirmDialogOpen,
    RequestAnotherInterviewConfirmDialogData,
} from '@/_selectors';
import { ProcessStatus, ProcessSubstatus } from '@/lib/api/v1';
import { DialogName } from '@/lib/types';
import { getMixpanelCandidateData } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { connect, useSelector } from 'react-redux';

import RequestAnotherInterviewConfirmDialog from './RequestAnotherInterviewConfirmDialog';

function RequestAnotherInterviewConfirmDialogProvider({
    setDialogOpenAction,
    updateProcessStatus,
}: {
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
    updateProcessStatus: (params: PatchProcessStatus) => Promise<void>;
}) {
    const shouldBeOpen = useSelector(IsRequestAnotherInterviewConfirmDialogOpen);
    const { process } = useSelector(RequestAnotherInterviewConfirmDialogData);

    const hasData = !!process;
    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.RequestAnotherInterviewConfirm, open);
    };

    const handleClose = () => setOpen(false);

    const handleConfirm = async () => {
        trackEvent(
            MixpanelEvent.ProcessActionsConfirmRequestAnotherInterview,
            getMixpanelCandidateData(process!),
        );
        await updateProcessStatus({
            processId: process!.id,
            status: ProcessStatus.InPersonInterview,
            subStatus: ProcessSubstatus.Pending,
        });
    };

    return (
        <>
            {hasData && (
                <RequestAnotherInterviewConfirmDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                    onConfirm={handleConfirm}
                />
            )}
        </>
    );
}

const mapDispatchToProps = {
    setDialogOpenAction: setDialogOpen,
    updateProcessStatus,
};

export default connect(
    null,
    mapDispatchToProps,
)(RequestAnotherInterviewConfirmDialogProvider);
