import {
    Button,
    CustomPopover,
    PopoverMenu,
    RegularPopoverMenuItem,
    SelectionButton,
} from '@/components/ui';
import { ButtonVariantProps } from '@/components/ui/button/variants';
import { cn } from '@/lib/utils';
import { useState } from 'react';

export function ActionsMenuOrButton({
    items,
    trackOpenMenu,
    variant,
    menuLabel,
    fullWidth = false,
}: {
    items: RegularPopoverMenuItem[];
    trackOpenMenu: () => void;
    variant: ButtonVariantProps['variant'];
    menuLabel: string;
    fullWidth?: boolean;
}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isButton = items.length === 1;
    const buttonProps = items[0];
    if (items.length === 0) {
        return null;
    }
    return (
        <>
            {isButton && (
                <Button
                    className={cn('xs:w-full px-6', {
                        'w-full text-13 justify-start': fullWidth,
                    })}
                    size={'smThin'}
                    onClick={buttonProps.onClick}
                >
                    {buttonProps.content.label}
                </Button>
            )}
            {!isButton && (
                <CustomPopover
                    trigger={
                        <div onClick={(e) => e.stopPropagation()}>
                            <SelectionButton
                                variant={variant}
                                open={isMenuOpen}
                                label={menuLabel}
                                onClick={trackOpenMenu}
                                className={cn({
                                    'w-full text-13 justify-between': fullWidth,
                                })}
                            />
                        </div>
                    }
                    triggerProps={{ asChild: true }}
                    open={isMenuOpen}
                    setOpen={setIsMenuOpen}
                    contentProps={{
                        variant: 'noPointer',
                        align: 'end',
                        alignOffset: 0,
                        sideOffset: 6,
                    }}
                >
                    <PopoverMenu
                        rootProps={{ onClick: (e) => e.stopPropagation() }}
                        items={items}
                        itemsProps={{ rootProps: { className: cn('h-14 xs:h-[64px]') } }}
                    />
                </CustomPopover>
            )}
        </>
    );
}

export default ActionsMenuOrButton;
