import { AppDispatch } from '@/_helpers/store';
import apiClient from '@/lib/api';
import { EventStatus, EventType } from '@/lib/api/v1';
import { CandidateEventApiModel } from '@/lib/types';

import processesSlice, { fetchProcess } from '../processes';
import eventsSlice, { storeEvents } from './eventsSlice';
import { FetchProcessEvents, FetcherName, SubmitEventNoShowParams } from './types';

const fetchEvents = async ({
    ids,
    processes,
    eventType,
    status,
    orderBy,
}: FetchProcessEvents) => {
    const response = await apiClient.GET('/api/v1/candidate-events', {
        params: {
            query: {
                limit: 50,
                id__in: ids?.join(','),
                process_id__in: processes?.join(','),
                ...(Array.isArray(eventType)
                    ? { event_types: eventType }
                    : { eventType }),
                status,
                order_by: orderBy,
            },
        },
    });
    return response.data!.items as CandidateEventApiModel[];
};

export const fetchUpcomingEvents = () => (dispatch: AppDispatch) => {
    const thunk = dispatch(
        storeEvents({
            fetcherId: FetcherName.UpcomingEvents,
            fetchFunction: () =>
                fetchEvents({
                    eventType: [
                        EventType.PhoneInterview,
                        EventType.InPersonInterview,
                        EventType.Orientation,
                    ],
                    status: EventStatus.Scheduled,
                }),
        }),
    );
    thunk.unwrap().then((events) => {
        const processIds = events.map((event) => event.processId);
        dispatch(processesSlice.actions.markIdsToFetch(processIds));
    });
};

export const fetchEventsById =
    (ids: string[], fetcher?: FetcherName) => (dispatch: AppDispatch) =>
        dispatch(
            storeEvents({
                fetcherId: fetcher,
                fetchFunction: () =>
                    fetchEvents({
                        ids: ids,
                    }),
            }),
        );

export const resetEvents = () => (dispatch: AppDispatch) => {
    dispatch(eventsSlice.actions.reset());
};

export const requestRescheduleEvent =
    (eventId: string) => async (dispatch: AppDispatch) => {
        const response = await apiClient.POST(
            '/api/v1/candidate-events/{event_id}/cancel',
            {
                params: {
                    path: { event_id: eventId },
                },
                body: {
                    requestReschedule: true,
                },
            },
        );
        dispatch(
            eventsSlice.actions.updateEventStatus(
                response.data as CandidateEventApiModel,
            ),
        );
        const processId = response.data!.processId;
        await dispatch(fetchProcess(processId));
    };

export const submitEventNoShow =
    ({ eventId, noShowBy }: SubmitEventNoShowParams) =>
    async (dispatch: AppDispatch) => {
        const response = await apiClient.POST(
            '/api/v1/candidate-events/{event_id}/no-shows',
            {
                params: {
                    path: { event_id: eventId },
                },
                body: {
                    noShowBy,
                },
            },
        );
        dispatch(
            eventsSlice.actions.updateEventStatus(
                response.data as CandidateEventApiModel,
            ),
        );
        const processId = response.data!.processId;
        await dispatch(fetchProcess(processId));
    };
