import { genericToasts } from '../common';

const strings = {
    title: 'Confirm request for in-person interview',
    description: {
        func: (firstName: string) =>
            `We will contact ${firstName} to schedule an in-person interview at the facility.`,
        example: ['Candy'],
    },
    cancelButton: 'Cancel',
    confirmButton: 'Confirm',
    successStep: {
        title: {
            func: (firstName: string) => `${firstName}'s process was updated`,
            example: ['Candy'],
        },
        description: {
            func: (firstName: string) =>
                `Great! We will contact ${firstName} to schedule the interview. You'll get notified with any progress.`,
            example: ['Candy'],
        },
    },
    submitFailure: {
        ...genericToasts.failure,
    },
};

export default strings;
