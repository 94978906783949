import { genericToasts } from '../common';

const CONFIRM_BUTTON = 'Confirm';

const strings = {
    title: {
        interview: 'Confirm interview did not happen',
        orientation: 'Confirm orientation did not happen',
    },
    description: {
        interview:
            "You couldn't make it to the interview? No worries! \n" +
            `Click "${CONFIRM_BUTTON}" below to request a new one.`,
        orientation:
            'The orientation did not take place? No worries! \n' +
            `Click "${CONFIRM_BUTTON}" below and we will notify the candidate.`,
    },
    cancelButton: 'Cancel',
    confirmButton: CONFIRM_BUTTON,
    successStep: {
        title: "Great, we're on it!",
        description: {
            interview: {
                func: (firstName: string) =>
                    `We'll reach out to ${firstName} to schedule a new time for this interview.`,
                example: ['Candy'],
            },
            orientation: {
                func: (firstName: string) =>
                    `We'll make sure ${firstName} will attend the next orientation.`,
                example: ['Candy'],
            },
        },
    },
    submitFailure: {
        ...genericToasts.failure,
    },
};

export default strings;
