import { XIcon } from '@/components/svgs';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { DivAttributes } from '@/lib/base';
import { cn } from '@/lib/utils';

import { DialogHeader } from './base';
import { ContentViewProps } from './types';
import { useDragToCloseDialogDispatcher } from './useDraggableDialog';

export function DialogHeaderAnchor({ ...props }: DivAttributes) {
    return (
        <div
            {...props}
            className={cn('w-full pt-6 flex justify-center', props?.className)}
        >
            <div className={cn('w-12 h-[4px] bg-gray-400 rounded-2xl')} />
        </div>
    );
}

function DialogContentView({
    customHeader,
    headerProps,
    titleProps,
    title,
    children,
    headerAnchorProps,
    handleClose,
}: ContentViewProps) {
    const isMobileScreen = useIsMobileScreen();
    const { bindDragProps } = useDragToCloseDialogDispatcher(true);
    return (
        <>
            <div
                {...(isMobileScreen ? bindDragProps() : {})}
                className={cn('touch-none')}
            >
                {customHeader}
                {!customHeader && (
                    <>
                        {isMobileScreen && <DialogHeaderAnchor {...headerAnchorProps} />}
                        <DialogHeader {...headerProps}>
                            <div
                                {...titleProps}
                                className={cn('w-full pr-4', titleProps?.className)}
                            >
                                {title}
                            </div>
                            <div className={cn('h-full flex items-start')}>
                                <div
                                    className={cn(
                                        'h-8 w-8 flex items-center justify-center cursor-pointer',
                                    )}
                                    onClick={handleClose}
                                >
                                    <XIcon className={cn('w-4 h-4')} />
                                </div>
                            </div>
                        </DialogHeader>
                    </>
                )}
            </div>
            {children}
        </>
    );
}
export default DialogContentView;
