import { SelectNoSelection } from '@/components/ui/select';
import {
    ProcessStatus,
    ProcessSubstatus,
    RecruiterApprovalStatus,
    RecruiterTerminationReason,
} from '@/lib/api/v1';
import { FetcherState, ProcessApiModel, ProcessModelInStore } from '@/lib/types';

export interface FetchProcesses {
    status?: ProcessStatus | ProcessStatus[];
    subStatus?: ProcessSubstatus | ProcessSubstatus[];
    processIds?: string[];
    recrtuierApprovalStatus?: RecruiterApprovalStatus | null;
    limit?: number;
}

export type FetchProcessesFunc = () => Promise<ProcessApiModel[]>;

export interface PatchProcessStatus {
    processId: string;
    status:
        | ProcessStatus.Hired
        | ProcessStatus.PhoneInterview
        | ProcessStatus.InPersonInterview
        | ProcessStatus.Paperwork
        | ProcessStatus.Orientation;
    subStatus: ProcessSubstatus;
}

export interface UpdateJobMatches {
    processId: string;
    selectedMatchId: string;
}

export interface PostTerminateProcess {
    processId: string;
    reason: RecruiterTerminationReason;
    additionalText?: string;
    byCandidateNoShow?: boolean;
}

export type RejectCandidateParams = Omit<PostTerminateProcess, 'reason'> & {
    reason?: PostTerminateProcess['reason'] | SelectNoSelection;
};

export interface SendProcessNoteParams {
    processId: string;
    text: string;
}

export enum FetcherName {
    MissingForEvents = 'missingForEvents',
    ByProcessId = 'byProcessId',
    InProgress = 'inProgress',
    PendingFeedback = 'pendingFeedback',
    NewProcesses = 'newProcesses',
}

export interface ProcessesState {
    data: Record<string, ProcessModelInStore | undefined>;
    fetchers: Record<FetcherName, FetcherState>;
}

export interface FetchResumeLinkProps {
    candidateId: string;
}

export interface SetResumeLinkPayload {
    processId: string;
    resumeLink: string;
}
