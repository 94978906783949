import { EventType, ProcessStatus, models } from '@/lib/api/v1';

export enum ApiErrorType {
    INTERNAL_ERROR = 'INTERNAL_SERVER_ERROR',
    PHONE_NUMBER_TAKEN = 'PHONE_NUMBER_TAKEN',
    REQUEST_VALIDATION_ERROR = 'REQUEST_VALIDATION_ERROR',
    INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
}

export type ApiError = {
    detail: string;
    type?: ApiErrorType;
    traceId?: string;
    extra_data?: object;
};

export class ApiResponseError extends Error implements ApiError {
    detail: string;
    message: string;
    type?: ApiErrorType;
    traceId?: string;
    extra_data?: object;
    responseStatusCode: number;

    constructor({
        detail,
        status,
        type,
        traceId,
        extra_data,
    }: {
        detail: string;
        status: number;
        type?: ApiErrorType;
        traceId?: string;
        extra_data?: object;
    }) {
        super(detail);
        this.name = 'ApiResponseError';
        this.detail = detail;
        this.message = detail;
        this.responseStatusCode = status;
        this.type = type;
        this.traceId = traceId;
        this.extra_data = extra_data;
    }
}

export type CandidateApiModel = models['FlatCandidateOut__ApiModel'];
export type Candidate = CandidateApiModel; // no relevant Date objects
export type EventCandidate = models['EventCandidateOut'];

export type CandidateWithResumeUrl = Candidate & {
    resumeUrl?: string | null;
};
export type ProcessApiModel = models['ProcessOut__ApiModel'];
export type ProcessModelInStore = Omit<ProcessApiModel, 'candidate'> & {
    candidate: CandidateWithResumeUrl;
};
export type Process = Omit<models['ProcessOut'], 'relevantEvent' | 'candidate'> & {
    relevantEvent?: FlatCandidateEvent;
    candidate: CandidateWithResumeUrl;
};

export type CandidateEventApiModel = models['CandidateEventOut__ApiModel'];
export type CandidateEvent = models['CandidateEventOut'];
export type FlatCandidateEventApiModel = models['FlatCandidateEventOut__ApiModel'];
export type FlatCandidateEvent = models['FlatCandidateEventOut'];

export type JobMatchApiModel = models['JobMatchOut__ApiModel'];
export type JobMatch = JobMatchApiModel;

export type ProcessHistoryApiModel = models['ProcessHistoryOut__ApiModel'];
export type ProcessHistory = models['ProcessHistoryOut'];
export type ProcessHistoryAction = models['ProcessHistoryOut']['action'];

export const processStatusToEventTypeMap: Partial<Record<ProcessStatus, EventType>> = {
    [ProcessStatus.PhoneInterview]: EventType.PhoneInterview,
    [ProcessStatus.InPersonInterview]: EventType.InPersonInterview,
    [ProcessStatus.Orientation]: EventType.Orientation,
};

// TODO: cleanup this
export enum DeprecatedProcessAction {
    RequestRescheduleEvent = 'request_reschedule_event',
}
