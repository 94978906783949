const strings = {
    actions: {
        menuLabels: {
            otherOptions: 'Other options',
            moveForward: 'Move forward',
            generic: 'Actions',
        },
        moveToOffer: 'Proceed to offer',
        markHired: 'Orientation done',
        candidateNoShow: "Candidate didn't show up",
        recruiterNoShow: {
            interview: "Recruiter couldn't make it",
            orientation: "Orientation didn't happen",
        },
        reschedule: 'Reschedule event',
        terminate: 'Reject candidate',
        requestAnotherInterview: 'Request in-person interview',
    },
};

export default strings;
