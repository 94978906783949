import { ProcessStatus } from '@/lib/api/v1';
import { Process, ProcessActionButton } from '@/lib/types';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { process as strings } from '@/strings';

import ActionsMenuOrButton from './ActionsMenuOrButton';
import { InterviewFeedbackActionsProps } from './types';
import { useProcessActionMenuItems } from './useAllowedProcessActions';

const eventFeedbackPositiveActions: ProcessActionButton[] = [
    ProcessActionButton.MoveToOffer,
    ProcessActionButton.RequestAnotherInterview,
    ProcessActionButton.MarkHired,
];

function filterRelevantActions(process: Process, action: ProcessActionButton) {
    if (
        action === ProcessActionButton.RequestAnotherInterview &&
        process.status !== ProcessStatus.PhoneInterview
    ) {
        return false;
    }
    if (
        action === ProcessActionButton.MarkHired &&
        process.status !== ProcessStatus.Orientation
    ) {
        return false;
    }
    if (
        action === ProcessActionButton.MoveToOffer &&
        [ProcessStatus.Orientation, ProcessStatus.Paperwork].includes(process.status)
    ) {
        return false;
    }
    return true;
}

export function PostEventFeedbackActionsView({
    rootProps,
    process,
    isVertical = false,
    context,
}: InterviewFeedbackActionsProps) {
    const menuItems = useProcessActionMenuItems(process);
    const relevantMenuItems = menuItems.filter(({ id }) =>
        filterRelevantActions(process, id),
    );
    const goodActions = relevantMenuItems.filter(({ id }) =>
        eventFeedbackPositiveActions.includes(id),
    );
    const badActions = relevantMenuItems.filter(
        ({ id }) => !eventFeedbackPositiveActions.includes(id),
    );
    return (
        <div
            {...rootProps}
            className={cn(
                'flex items-center gap-4',
                { 'items-stretch flex-col-reverse w-full': isVertical },
                rootProps?.className,
            )}
        >
            <ActionsMenuOrButton
                items={badActions}
                variant={'outlinedAction'}
                menuLabel={strings.actions.menuLabels.otherOptions}
                trackOpenMenu={() => {
                    trackEvent(MixpanelEvent.ProcessEventFeedbackOpenActionsMenu, {
                        event_type: process.relevantEvent!.eventType,
                        candidate_id: process.candidate.id,
                        candidate_name: process.candidate.name,
                        actions_menu: 'other_options',
                        via: context,
                    });
                }}
                fullWidth={isVertical}
            />
            <ActionsMenuOrButton
                items={goodActions}
                variant={'default'}
                menuLabel={strings.actions.menuLabels.moveForward}
                trackOpenMenu={() => {
                    trackEvent(MixpanelEvent.ProcessEventFeedbackOpenActionsMenu, {
                        event_type: process.relevantEvent!.eventType,
                        candidate_id: process.candidate.id,
                        candidate_name: process.candidate.name,
                        actions_menu: 'proceed_forward',
                        via: context,
                    });
                }}
                fullWidth={isVertical}
            />
        </div>
    );
}

export default PostEventFeedbackActionsView;
