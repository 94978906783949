import { genericToasts } from '../common';

const strings = {
    title: "Choose what you'd like to happen next",
    description: {
        interview:
            'We do our best to ensure candidates show up to interviews on time. \n' +
            'What would you like to do next?',
        orientation:
            'We do our best to ensure candidates show up to orientations on time. \n' +
            'What would you like to do next?',
    },
    terminateButton: 'Reject candidate',
    confirmButton: {
        interview: 'Reschedule interview',
        orientation: 'Reschedule orientation',
    },
    successStep: {
        title: "Great, we're on it!",
        description: {
            interview: {
                func: (firstName: string) =>
                    `We'll reach out to ${firstName} to schedule a new time for this interview.`,
                example: ['Candy'],
            },
            orientation: {
                func: (firstName: string) =>
                    `We'll make sure ${firstName} will attend the next orientation.`,
                example: ['Candy'],
            },
        },
    },
    submitFailure: {
        ...genericToasts.failure,
    },
};

export default strings;
