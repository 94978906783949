import { ProcessStatus, ProcessSubstatus } from '@/lib/api/v1';
import { ProcessModelInStore } from '@/lib/types';
import {
    isInProcess,
    isNewProcess,
    isPhoneInterviewDoneProc,
    isTerminated,
    keysOf,
    parseProcessApiModel,
    serializeFetcherState,
    valuesOf,
} from '@/lib/utils';
import { map, mapValues, pickBy } from 'lodash';
import { createSelector } from 'reselect';

import { ProcessesState } from './base';

export const ProcessesDataState = createSelector(ProcessesState, (s) => s.data);

export const AllProcesses = createSelector(ProcessesDataState, (processesState) => {
    const processesFetched = pickBy(processesState, (p) => p !== undefined) as Record<
        string,
        ProcessModelInStore
    >;
    return mapValues(processesFetched, parseProcessApiModel);
});

export const Processes = createSelector(AllProcesses, (allProcesses) => {
    return pickBy(allProcesses, (p) => !isTerminated(p));
});

export const ProcessesIdsToFetch = createSelector(ProcessesDataState, (processesState) =>
    keysOf(pickBy(processesState, (p) => p === undefined)),
);

export const PhoneInterviewDoneProcessesIds = createSelector(Processes, (processes) =>
    keysOf(
        pickBy(
            processes,
            (p) =>
                p.substatus === ProcessSubstatus.Done &&
                p.status === ProcessStatus.PhoneInterview,
        ),
    ),
);

export const ProcessIds = createSelector(Processes, (processesById) =>
    keysOf(processesById),
);

export const NewProcessesFetcherData = createSelector(ProcessesState, (processesState) =>
    serializeFetcherState(processesState.fetchers.newProcesses),
);

export const InProcessProcessesFetcherData = createSelector(
    ProcessesState,
    (processesState) => serializeFetcherState(processesState.fetchers.inProgress),
);

export const ByIdProcessesFetcherData = createSelector(
    ProcessesState,
    (processesState) => serializeFetcherState(processesState.fetchers.byProcessId),
);

export const MissingForEventsProcessesFetcherData = createSelector(
    ProcessesState,
    (processesState) => serializeFetcherState(processesState.fetchers.missingForEvents),
);

export const NewProcesses = createSelector(Processes, (processes) =>
    pickBy(processes, (p) => isNewProcess(p)),
);

export const InterviewDoneProcessIds = createSelector(Processes, (processes) =>
    map(
        pickBy(processes, (p) => isPhoneInterviewDoneProc(p)),
        'id',
    ),
);

export const InProcessProcesses = createSelector(Processes, (processes) =>
    pickBy(processes, (p) => isInProcess(p)),
);

export const ProcessRelevantEventIds = createSelector(
    Processes,
    (processes) =>
        valuesOf(processes)
            .map((p) => p.relevantEvent?.id)
            .filter((id) => !!id) as string[],
);

export const PendingTasksFetcherData = createSelector(ProcessesState, (processesState) =>
    serializeFetcherState(processesState.fetchers.pendingFeedback),
);
