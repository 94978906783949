import { RootState, useAppDispatch } from '@/_helpers/store';
import { CurrentRecruiterId, IsLoggedIn } from '@/_selectors';
import { FetcherFunc, FetcherStateData } from '@/lib/types';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useFetcherRefresh from './useFetcherRefresh';

const FetchersManager = {
    fetched: new Set(),
    reset: function () {
        this.fetched = new Set();
    },
};

export function resetFetcherMeneger() {
    FetchersManager.reset();
}

interface Props<D> {
    fetchFunc: FetcherFunc;
    dataSelector: (state: RootState) => D;
    fetcherSelector: (state: RootState) => FetcherStateData;
}

function useFetcherBase<D>({ fetchFunc, dataSelector, fetcherSelector }: Props<D>) {
    const dispatch = useAppDispatch();
    const isLoggedIn = useSelector(IsLoggedIn);
    const currentRecruiterId = useSelector(CurrentRecruiterId);
    const data = useSelector(dataSelector);
    const { initiated, isLoading } = useSelector(fetcherSelector);
    const fetch = useCallback(() => {
        dispatch(fetchFunc());
    }, [fetchFunc, dispatch]);

    useEffect(() => {
        if (
            !initiated &&
            isLoggedIn &&
            currentRecruiterId &&
            !FetchersManager.fetched.has(fetchFunc)
        ) {
            FetchersManager.fetched.add(fetchFunc);
            dispatch(fetchFunc());
        }
    }, [initiated, isLoggedIn, currentRecruiterId, dispatch, fetchFunc]);

    const refresh = useFetcherRefresh({ refresh: fetch, initiated });

    return {
        initiated,
        isLoading,
        isWaiting: !initiated && isLoading,
        refresh,
        data,
    };
}

export default useFetcherBase;
