import { ArrowDown } from '@/components/svgs';
import { cn } from '@/lib/utils';

import { Button } from './base';
import { SelectionButtonProps } from './types';
import { whiteTextVariants } from './variants';

function SelectionButton({
    label,
    open,
    className,
    ...buttonProps
}: SelectionButtonProps) {
    const buttonVariant = buttonProps?.variant ?? 'default';
    return (
        <Button
            className={cn(
                'flex items-center h-12 gap-4',
                { 'pointer-events-none': open },
                className,
            )}
            size={'smThin'}
            allowPropagation
            {...buttonProps}
            variant={buttonVariant}
        >
            <div>{label}</div>
            <ArrowDown
                className={cn('fill-primary stroke-primary h-2', {
                    'fill-white stroke-white': whiteTextVariants.includes(buttonVariant),
                })}
            />
        </Button>
    );
}

export default SelectionButton;
