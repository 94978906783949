import { FillingAvailabilityRequired } from '@/_selectors';
import { Button, LoadingButton, useToast } from '@/components/ui';
import { cn } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { candidates as candidatesStrings } from '@/strings';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { NewProcessActionViewProps } from './types';

const strings = candidatesStrings.newRequests.newCandidateActions;

export function NewProcessActionsView({
    process,
    loading: outerLoading,
    setLoading: setOuterLoading,
    openWeeklyAvailabilityMissingDialog,
    openRejectConfirmationDialog,
    setAvailabilityDialogCallback,
    approveCandidate,
    approveButtonProps,
    rejectButtonProps,
    containerProps,
}: NewProcessActionViewProps) {
    const [innerLoading, setInnerLoading] = useState(false);
    const isOuterLoading = !!setOuterLoading;
    const loading = isOuterLoading ? outerLoading : innerLoading;
    const setLoading = isOuterLoading ? setOuterLoading : setInnerLoading;
    const { toast } = useToast();
    const avRequired = useSelector(FillingAvailabilityRequired);

    const approve = async () => {
        setLoading(true);
        await approveCandidate(process.id)
            .then(() => {
                toast({
                    title: strings.approveCandidate.successTitle,
                    description: strings.approveCandidate.successSubtitle.func(
                        process.candidate.name,
                    ),
                });
            })
            .catch((error) => {
                toast({
                    title: strings.approveCandidate.failureTitle,
                    description: error.message || error,
                    variant: 'destructive',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onApproveClick = async () => {
        trackEvent(MixpanelEvent.ApproveCandidate, {
            candidate_name: process.candidate.name,
            candidate_id: process.candidate.id,
        });
        if (avRequired) {
            setAvailabilityDialogCallback(approve);
            openWeeklyAvailabilityMissingDialog();
        } else {
            return await approve();
        }
    };

    const onRejectClick = () => {
        trackEvent(MixpanelEvent.NewRequestTerminateCandidate, {
            candidate_name: process.candidate.name,
            candidate_id: process.candidate.id,
        });
        openRejectConfirmationDialog(process.id);
    };

    const {
        className: approveBtnCls,
        show: showApprove = true,
        ...approveBtnProps
    } = approveButtonProps ?? {};
    const {
        className: rejectBtnCls,
        show: showReject = true,
        ...rejectBtnProps
    } = rejectButtonProps ?? {};

    return (
        <div className={cn('flex items-center gap-5', containerProps?.className)}>
            {showReject && (
                <Button
                    disabled={loading}
                    className={cn({ 'cursor-default': loading }, rejectBtnCls)}
                    variant={'secondary'}
                    onClick={onRejectClick}
                    {...rejectBtnProps}
                >
                    {strings.buttonReject}
                </Button>
            )}
            {showApprove && (
                <LoadingButton
                    disabled={loading}
                    variant={'default'}
                    className={cn({ 'cursor-default': loading }, approveBtnCls)}
                    onClick={onApproveClick}
                    {...approveBtnProps}
                >
                    {strings.buttonApprove}
                </LoadingButton>
            )}
        </div>
    );
}

export default NewProcessActionsView;
