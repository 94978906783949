import { DialogName } from '@/lib/types/dialogs';
import { createSelector } from 'reselect';

import { DialogsState } from './base';

export const DialogsOpenState = createSelector(DialogsState, (dialogs) => dialogs.open);

export const DialogsDataState = createSelector(DialogsState, (dialogs) => dialogs.data);

export const IsRecruiterAvailabilityDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.RecruiterAvailability],
);

export const IsRejectCandidateConfirmDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.RejectCandidateConfirm],
);

export const IsWeeklyAvMissingDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.WeeklyAvailabilityMissing],
);

export const IsCandidateDetailsDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.CandidateDetails],
);

export const IsRescheduleEventConfirmDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.RescheduleEventConfirm],
);
export const IsMoveToOfferConfirmDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.MoveToOfferConfirm],
);

export const IsTerminateProcessDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.TerminateProcess],
);

export const IsEventDetailsDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.EventDetails],
);

export const CandidateDetailsDialogBaseData = createSelector(
    DialogsDataState,
    (dataState) => {
        const { processId } = dataState[DialogName.CandidateDetails] ?? {};
        return { processId };
    },
);

export const IsMarkHiredConfirmDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.MarkHiredConfirm],
);

export const IsRequestAnotherInterviewConfirmDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.RequestAnotherInterviewConfirm],
);

export const IsRecruiterNoShowConfirmDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.RecruiterNoShowConfirm],
);

export const IsCandidateNoShowDialogOpen = createSelector(
    DialogsOpenState,
    (isOpenByDialogId) => !!isOpenByDialogId[DialogName.CandidateNoShow],
);
