import { cn } from '@/lib/utils';
import PostEventFeedbackActionsView from '@/processes/actions/PostEventFeedbackActionsView';

import { TaskBlockActionsProps } from './types';

function TaskBlockActions({ process, isVertical }: TaskBlockActionsProps) {
    return (
        <div className={cn('flex items-center')}>
            <PostEventFeedbackActionsView
                process={process}
                context={'pending_task_post_event_feedback'}
                isVertical={isVertical}
            />
        </div>
    );
}

export default TaskBlockActions;
