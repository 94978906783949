import { AppDispatch } from '@/_helpers/store';
import { setImpersonatedUser } from '@/_reducers/auth';
import { TurnOffIcon } from '@/components/svgs';
import { Button, Dialog, Select, useDialogContext, useToast } from '@/components/ui';
import apiClient from '@/lib/api';
import { models } from '@/lib/api/v1';
import { UserRole } from '@/lib/types';
import { cn, signOutFirebase } from '@/lib/utils';
import { keyBy, sortBy } from 'lodash';
import { VenetianMaskIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

function ImpersonationDialog({ open }: { open: boolean }) {
    const { toast } = useToast();
    const { handleClose } = useDialogContext();
    const [recruiters, setRecruiters] = useState<models['RecruiterOut'][]>([]);
    const [selected, setSelected] = useState<string>('');
    const dispatch = useDispatch<AppDispatch>();

    const recruitersById = keyBy(recruiters, 'id');
    const selectedRecruiter = recruitersById[selected];

    const impersonate = () => {
        if (selectedRecruiter) {
            dispatch(
                setImpersonatedUser({
                    id: selectedRecruiter.id,
                    email: selectedRecruiter.email,
                    name: selectedRecruiter.name,
                    role: UserRole.Recruiter,
                }),
            );
            handleClose();
        }
    };

    useEffect(() => {
        apiClient
            .GET('/api/v1/recruiters')
            .then(({ data }) => {
                if (data) {
                    setRecruiters(
                        sortBy(
                            data.items.filter((r) => !!r.email),
                            'name',
                        ),
                    );
                }
            })
            .catch((e) => {
                toast({
                    title: 'Failed to fetch recruiters',
                    description: e.message || e,
                    variant: 'destructive',
                });
            });
    }, [toast]);

    return (
        <Dialog
            open={open}
            customHeader={
                <div className={cn('p-10 flex items-center')}>
                    <VenetianMaskIcon className={cn('w-16 h-16')} />
                    <h2 className={cn('ml-6 font-bold text-2xl')}>
                        Impersonate A Recruiter
                    </h2>
                </div>
            }
        >
            <div className={cn('p-12 pt-4')}>
                <div>
                    <p className={cn('text-black text-13 font-semibold')}>
                        Impersonated User:
                    </p>
                    <Select
                        options={recruiters.map((r) => ({
                            id: r.id,
                            value: r.id,
                            label: `${r.name} (${r.email})`,
                        }))}
                        value={selected}
                        onChange={setSelected}
                        placeholder={'Select Recruiter'}
                        triggerProps={{
                            className: cn('mt-4'),
                        }}
                    />
                </div>
                <div
                    className={cn(
                        'w-full mt-20 flex gap-2 justify-between items-center',
                    )}
                >
                    <Button onClick={impersonate} disabled={!selected}>
                        Impersonate!
                    </Button>
                    <Button
                        className={cn('flex items-center gap-2')}
                        variant={'secondary'}
                        size={'tiny'}
                        onClick={signOutFirebase}
                    >
                        <TurnOffIcon />
                        <div>Sign Out</div>
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default ImpersonationDialog;
