import { IsLoggedIn } from '@/_selectors';
import useCurrentPageId from '@/hooks/useCurrentPageId';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useMixpanelContext } from './MixpanelController';
import { MixpanelEvent, trackEvent } from './events';

const useTrackPageView = (pageName?: string) => {
    const location = useLocation();
    const pageId = useCurrentPageId();
    const isLoggedIn = useSelector(IsLoggedIn);
    const { initiated } = useMixpanelContext();
    const pageDisplayName = pageName ?? pageId;

    useEffect(() => {
        if (!initiated) {
            return;
        }
        if (!pageName && !isLoggedIn) {
            return;
        }
        trackEvent(MixpanelEvent.PageView, {
            page: pageDisplayName ?? '',
            path: location.pathname,
            search: location.search,
        });
    }, [location, initiated, pageId, pageDisplayName, pageName, isLoggedIn]);
};

export default useTrackPageView;
