import {
    DialogNameWithData,
    openDialogDismissOthers,
    setDialogOpen,
} from '@/_reducers/dialogs';
import { SubmitEventNoShowParams, submitEventNoShow } from '@/_reducers/events';
import { CandidateNoShowDialogData, IsCandidateNoShowDialogOpen } from '@/_selectors';
import { ProcessParty, RecruiterTerminationReason } from '@/lib/api/v1';
import { DialogName } from '@/lib/types';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { connect, useSelector } from 'react-redux';

import CandidateNoShowDialog from './CandidateNoShowDialog';

function CandidateNoShowDialogProvider({
    setDialogOpenAction,
    openDialogDismissOthers,
    submitEventNoShow,
}: {
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
    submitEventNoShow: (params: SubmitEventNoShowParams) => Promise<void>;
    openDialogDismissOthers: (...args: DialogNameWithData) => void;
}) {
    const shouldBeOpen = useSelector(IsCandidateNoShowDialogOpen);
    const { process, event } = useSelector(CandidateNoShowDialogData);

    const hasData = !!process && !!event;
    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.CandidateNoShow, open);
    };

    const handleClose = () => setOpen(false);

    const reschedule = async () => {
        trackEvent(MixpanelEvent.CandidateNoShowRescheduleEvent);
        await submitEventNoShow({
            eventId: event!.id,
            noShowBy: ProcessParty.Candidate,
        });
    };
    const goToTerminate = async () => {
        trackEvent(MixpanelEvent.CandidateNoShowTerminate);
        openDialogDismissOthers(DialogName.TerminateProcess, {
            processId: process!.id,
            defaultSelection: RecruiterTerminationReason.Ghosting,
            byCandidateNoShow: true,
        });
    };

    return (
        <>
            {hasData && (
                <CandidateNoShowDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                    event={event}
                    onReschedule={reschedule}
                    goToTerminate={goToTerminate}
                />
            )}
        </>
    );
}

const mapDispatchToProps = {
    setDialogOpenAction: setDialogOpen,
    submitEventNoShow,
    openDialogDismissOthers,
};

export default connect(null, mapDispatchToProps)(CandidateNoShowDialogProvider);
