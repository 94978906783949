import Logger from '@/_helpers/logger';
import store from '@/_helpers/store';
import { refreshUserToken } from '@/_reducers/auth';
import config from '@/config';
import { CookieNames, extractMsgFromCatchedError } from '@/lib/base';
import { ApiResponseError, SentryContext } from '@/lib/types';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import createClient from 'openapi-fetch';
import { v4 as uuidv4 } from 'uuid';

import customHeaders, { CustomHeaderName } from './customHeaders';
import { extractApiError } from './utils';
import type { paths } from './v1';

async function responseOrRejectedPromise(response: Response) {
    if (!response.ok) {
        const error = await response.json();
        const parsedError = extractApiError(error);
        throw new ApiResponseError({
            ...parsedError,
            status: response.status,
        });
    }
    return response;
}

function updateTraceContext(traceId: string): void {
    if (traceId) {
        Sentry.setContext(SentryContext.BackendTracing, { trace_id: traceId });
    }
}

function addTraceIdToHeaders(headers: Headers): void {
    const traceId = uuidv4();
    headers.append(CustomHeaderName.TraceId, traceId);
}

function addCustomHeaders(headers: Headers) {
    for (const [header, value] of Object.entries(customHeaders)) {
        headers.append(header, value);
    }
}

function addTokenToRequestHeaders(headers: Headers) {
    const token = Cookies.get(CookieNames.authToken);
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }
}

function addHeadersToRequest(request?: RequestInit): RequestInit & { headers: Headers } {
    const headers = new Headers(request?.headers ?? {});
    addTokenToRequestHeaders(headers);
    addTraceIdToHeaders(headers);
    addCustomHeaders(headers);
    return {
        ...request,
        headers,
    };
}

const customFetch = async (input: RequestInfo | URL, init?: RequestInit) => {
    const request = addHeadersToRequest(init);
    const traceId = request.headers!.get(CustomHeaderName.TraceId)!;
    const response = await fetch(input, request).finally(() => {
        updateTraceContext(traceId);
    });
    if (response.status === 401 && !input.toString().includes('/api/v1/public')) {
        try {
            await store.dispatch(refreshUserToken()).unwrap();
            const request = addHeadersToRequest(init);
            const newTraceId = request.headers!.get(CustomHeaderName.TraceId)!;
            const finalResponse = await fetch(input, request).finally(() => {
                updateTraceContext(newTraceId);
            });
            return await responseOrRejectedPromise(finalResponse);
        } catch (error) {
            Logger.warn(`Refresh token failed ${extractMsgFromCatchedError(error)}`);
        }
    }
    return await responseOrRejectedPromise(response);
};

const apiClient = createClient<paths>({
    baseUrl: `${config.API_BASE_URL}`,
    fetch: customFetch,
});

export default apiClient;
