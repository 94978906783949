import { AppDispatch } from '@/_helpers/store';
import { registerAuthUser } from '@/_reducers/auth';
import useRemoveFirebaseParams from '@/hooks/useFirebaseParamsRemoval';
import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { cn, firebaseAuth, getLocalStorageGracefully } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import useTrackPageView from '@/mixpanel/useTrackPageView';
import strings from '@/strings/login';
import {
    UserCredential,
    isSignInWithEmailLink,
    signInWithEmailLink,
} from 'firebase/auth';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import LoginPageLayout from './LoginPageLayout';
import LoginPageMobileLayout from './LoginPageMobileLayout';
import { Step } from './types';

const trackEmailUpdate = debounce((email: string) => {
    trackEvent(MixpanelEvent.LoginUpdateEmailInput, { email });
}, 1500);

function LoginPage() {
    useTrackPageView('Login');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState(Step.Initial);
    const dispatch = useDispatch<AppDispatch>();
    const { removeFirebaseParams } = useRemoveFirebaseParams();
    const isMobile = useIsMobileScreen();

    const onEmailUpdate = (email: string) => {
        trackEmailUpdate(email);
        setEmail(email);
    };

    const onErrorUpdate = useCallback(
        (error: string) => {
            if (error) {
                trackEvent(MixpanelEvent.LoginGetErrorMsg, { error });
            }
            setError(error);
        },
        [setError],
    );

    const signInFromEmailLink = useCallback(
        async (userEmail: string) => {
            const url = window.location.href;
            return await signInWithEmailLink(firebaseAuth, userEmail.trim(), url)
                .then((creds: UserCredential) => {
                    getLocalStorageGracefully()?.removeItem('emailForSignIn');
                    dispatch(registerAuthUser(creds.user));
                })
                .catch((e) => {
                    onErrorUpdate(e.message ?? e);
                    setStep(Step.SignIn);
                })
                .finally(() => {
                    removeFirebaseParams();
                });
        },
        [dispatch, onErrorUpdate, removeFirebaseParams],
    );

    useEffect(() => {
        const url = window.location.href;
        if (!isSignInWithEmailLink(firebaseAuth, url)) {
            if (step === Step.Initial) {
                setStep(Step.SignIn);
            }
            return;
        }
        const email = getLocalStorageGracefully()?.getItem('emailForSignIn');
        if (!email) {
            onErrorUpdate(strings.emailInput.emailMissingFromCacheErrorMsg);
            setStep(Step.SignIn);
            return;
        }
        signInFromEmailLink(email);
    }, [step, setStep, signInFromEmailLink, onErrorUpdate]);

    if (step === Step.Initial) {
        return <div className={cn('flex h-screen w-screen bg-white')} />;
    }

    return isMobile ? (
        <LoginPageMobileLayout
            step={step}
            setStep={setStep}
            email={email}
            setEmail={onEmailUpdate}
            error={error}
            setError={onErrorUpdate}
            signInFromEmailLink={signInFromEmailLink}
        />
    ) : (
        <LoginPageLayout
            step={step}
            setStep={setStep}
            email={email}
            setEmail={onEmailUpdate}
            error={error}
            setError={onErrorUpdate}
            signInFromEmailLink={signInFromEmailLink}
        />
    );
}

export default LoginPage;
