import { EventType } from '@/lib/api/v1';
import { CandidateEvent, Process } from '@/lib/types';
import { getFirstName } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

import CandidateTitle from '../common/CandidateTitle';
import ConfirmationDialog from '../confirmation/ConfirmationDialog';
import { BaseDialogPropsCommon } from '../types';

const strings = dialogsStrings.recruiterNoShowConfirm;

interface Props extends BaseDialogPropsCommon {
    process: Process;
    event: CandidateEvent;
    onConfirm: () => Promise<void>;
}

function RecruiterNoShowConfirmDialog({
    process,
    event,
    onConfirm,
    ...dialogProps
}: Props) {
    const firstName = getFirstName(process.candidate);
    const eventStringType =
        event.eventType === EventType.Orientation ? 'orientation' : 'interview';
    return (
        <ConfirmationDialog
            {...dialogProps}
            header={<CandidateTitle process={process} />}
            content={{
                title: strings.title[eventStringType],
                description: strings.description[eventStringType],
                cancel: {
                    label: strings.cancelButton,
                    action: dialogProps.handleClose,
                },
                confirm: {
                    label: strings.confirmButton,
                    action: onConfirm,
                    failureTitle: strings.submitFailure.failureTitle,
                },
            }}
            successContent={{
                title: strings.successStep.title,
                description:
                    strings.successStep.description[eventStringType].func(firstName),
            }}
        />
    );
}

export default RecruiterNoShowConfirmDialog;
