import { PhoneIcon, TimeIcon } from '@/components/svgs';
import { ProfessionAvatar } from '@/components/ui';
import { EventType, ProcessAction } from '@/lib/api/v1';
import { Profession } from '@/lib/types';
import { cn, getFirstName } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { dialogs as dialogsStrings } from '@/strings';
import { Building2Icon } from 'lucide-react';

import EventActions from './EventActions';
import { EventDetailsStepProps, ImplementedEventType } from './types';

const strings = dialogsStrings.eventDetails;

export function InterviewEventContent({
    event,
    reschedule,
    cacnelEvent,
}: EventDetailsStepProps) {
    const { process, startAt, endAt } = event;
    const { candidate } = process;

    const onRescheduleClick = async () => {
        trackEvent(MixpanelEvent.EventDetailsRescheduleEvent);
        await reschedule();
    };
    const onCancelClick = async () => {
        trackEvent(MixpanelEvent.EventDetailsCancelEvent);
        cacnelEvent();
    };
    return (
        <div className={cn('w-full')}>
            <div
                className={cn(
                    'flex bg-phoneInterview items-center p-12 pb-14 xs:px-10 xs:pb-12 xs:pt-2',
                    {
                        'bg-inPersonInterview':
                            event.eventType === EventType.InPersonInterview,
                    },
                )}
            >
                <ProfessionAvatar
                    profession={candidate.profession as Profession}
                    className={cn(
                        'w-20 h-20 rounded-full outline-white outline-[3px] outline',
                    )}
                />
                <div className={cn('ml-5')}>
                    <p className={cn('text-16 font-bold')}>{candidate.name}</p>
                    <p className={cn('text-12 font-medium')}>
                        {strings.candidateSubtitle[candidate.profession as Profession]}
                    </p>
                </div>
            </div>
            <div className={cn('p-12  pb-14')}>
                <div>
                    <div className={cn('flex items-center')}>
                        <div
                            className={cn(
                                'w-[45px] h-[45px] mr-5 bg-accent200 rounded-[8px] flex items-center justify-center',
                            )}
                        >
                            <TimeIcon className={cn('w-6 h-6')} />
                        </div>
                        <div>
                            <p className={cn('text-11')}>{strings.timeLabel}</p>
                            <p className={cn('text-14 font-medium mt-1')}>
                                {strings.dateAndDuration.func(startAt, endAt)}
                            </p>
                        </div>
                    </div>
                    <div className={cn('flex items-center mt-10')}>
                        <div
                            className={cn(
                                'w-[45px] h-[45px] mr-5 bg-accent200 rounded-[8px] flex items-center justify-center',
                            )}
                        >
                            <PhoneIcon className={cn('w-6 h-6')} />
                        </div>
                        <div>
                            <p className={cn('text-11')}>
                                {strings.phoneLabel.func(getFirstName(candidate))}
                            </p>
                            <p className={cn('text-14 font-medium mt-1')}>
                                {strings.phoneNumber.func(candidate.phone)}
                            </p>
                        </div>
                    </div>
                    {event.facility && (
                        <div className={cn('flex items-center mt-10')}>
                            <div
                                className={cn(
                                    'w-[45px] h-[45px] mr-5 bg-accent200 rounded-[8px] flex items-center justify-center',
                                )}
                            >
                                <Building2Icon className={cn('w-6 h-6')} />
                            </div>
                            <div>
                                <p className={cn('text-11')}>{strings.facilityLabel}</p>
                                <p className={cn('text-14 font-medium mt-1')}>
                                    {event.facility.name}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div
                className={cn('flex justify-end px-10 py-8 border-t border-neutral100 ')}
            >
                {process.actions.includes(ProcessAction.CancelEvent) && (
                    <EventActions
                        eventType={event.eventType as ImplementedEventType}
                        reschedule={onRescheduleClick}
                        cancelEvent={onCancelClick}
                    />
                )}
            </div>
        </div>
    );
}

export default InterviewEventContent;
