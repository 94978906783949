import { AppDispatch } from '@/_helpers/store';
import { resetEvents } from '@/_reducers/events';
import { resetProcesses } from '@/_reducers/processes';
import { resetRecruiterAvailability } from '@/_reducers/recruiterAvailability';
import { resetFetcherMeneger } from '@/fetchers/useFetcherBase';
import { useDispatch } from 'react-redux';

function useResetRecruiterData() {
    const dispatch = useDispatch<AppDispatch>();
    const reset = () => {
        dispatch(resetProcesses());
        dispatch(resetEvents());
        dispatch(resetRecruiterAvailability());
        resetFetcherMeneger();
    };
    return {
        reset,
    };
}

export default useResetRecruiterData;
