import { Process } from '@/lib/types';
import { getFirstName } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

import CandidateTitle from '../common/CandidateTitle';
import ConfirmationDialog from '../confirmation/ConfirmationDialog';
import { BaseDialogPropsCommon } from '../types';

const strings = dialogsStrings.moveToOfferConfirm;

interface Props extends BaseDialogPropsCommon {
    process: Process;
    onConfirm: () => Promise<void>;
}

function MoveToOfferConfirmDialog({ process, onConfirm, ...dialogProps }: Props) {
    const { candidate } = process;
    const firstName = getFirstName(candidate);

    return (
        <ConfirmationDialog
            {...dialogProps}
            header={<CandidateTitle process={process} />}
            content={{
                title: strings.title.func(firstName),
                description: strings.description.func(getFirstName(candidate)),
                cancel: {
                    label: strings.cancelButton,
                    action: dialogProps.handleClose,
                },
                confirm: {
                    label: strings.confirmButton,
                    action: onConfirm,
                    failureTitle: strings.failureToast.failureTitle,
                },
            }}
            successContent={{
                title: strings.successStep.title.func(firstName),
                description: strings.successStep.description,
            }}
        />
    );
}

export default MoveToOfferConfirmDialog;
