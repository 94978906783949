import { genericToasts } from '../common';

const strings = {
    title: {
        func: (firstName: string) => `Confirm you made an offer to ${firstName}`,
        example: ['Candy'],
    },
    description: {
        func: (firstName: string) =>
            `We'll help ${firstName} with the paperwork once you confirm the offer.`,
        example: ['Candy'],
    },
    cancelButton: 'Cancel',
    confirmButton: 'Confirm',
    successStep: {
        title: {
            func: (firstName: string) => `${firstName}'s process was updated`,
            example: ['Candy'],
        },
        description:
            'We will keep tracking the progress with both sides of the process.',
    },
    failureToast: {
        ...genericToasts.failure,
    },
};

export default strings;
