import { genericToasts } from '../common';

const strings = {
    title: 'Confirm orientation is done',
    description: {
        func: (firstName: string) =>
            `This will let us know that ${firstName} has attented orientation and completed it successfully.`,
        example: ['Candy'],
    },
    cancelButton: 'Cancel',
    confirmButton: 'Confirm',
    successStep: {
        title: {
            func: (firstName: string) => `${firstName}'s process was updated`,
            example: ['Candy'],
        },
        description: "Congrats 🎉 That's another emplyee you got using Carefam!",
    },
    submitFailure: {
        ...genericToasts.failure,
    },
};

export default strings;
