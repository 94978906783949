import { DialogName } from '@/lib/types/dialogs';
import { createSelector } from 'reselect';

import {
    AllProcesses,
    CandidateDetailsDialogBaseData,
    DialogsDataState,
    Events,
    Processes,
} from '../generation_1';

export const RejectCandidateConfirmDialogData = createSelector(
    DialogsDataState,
    AllProcesses,
    (dataState, processesById) => {
        const { processId } = dataState[DialogName.RejectCandidateConfirm] ?? {};
        if (!processId) {
            return {};
        }
        return { process: processesById[processId] };
    },
);

export const TerminateProcessDialogData = createSelector(
    DialogsDataState,
    AllProcesses,
    (dataState, processesById) => {
        const { processId, defaultSelection, byCandidateNoShow } =
            dataState[DialogName.TerminateProcess] ?? {};
        if (!processId) {
            return {};
        }
        return {
            process: processesById[processId],
            defaultSelection,
            byCandidateNoShow,
        };
    },
);

export const RescheduleEventConfirmDialogData = createSelector(
    DialogsDataState,
    Events,
    Processes,
    (dataState, eventsById, processesById) => {
        const { eventId } = dataState[DialogName.RescheduleEventConfirm] ?? {};
        if (!eventId) {
            return {};
        }
        const event = eventsById[eventId];
        if (!event) {
            return {};
        }
        const process = processesById[event.processId];
        if (!process) {
            return {};
        }
        return { process, event };
    },
);

export const MoveToOfferConfirmDialogData = createSelector(
    DialogsDataState,
    Processes,
    (dataState, processesById) => {
        const { processId } = dataState[DialogName.MoveToOfferConfirm] ?? {};
        if (!processId) {
            return {};
        }
        const process = processesById[processId];
        if (!process) {
            return {};
        }
        return { process };
    },
);

export const CandidateDetailsDialogData = createSelector(
    CandidateDetailsDialogBaseData,
    AllProcesses,
    (baseData, processesById) => {
        const { processId } = baseData;
        if (!processId) {
            return {};
        }
        return { process: processesById[processId] };
    },
);

export const MarkHiredConfirmDialogData = createSelector(
    DialogsDataState,
    Processes,
    (dataState, processesById) => {
        const { processId } = dataState[DialogName.MarkHiredConfirm] ?? {};
        if (!processId) {
            return {};
        }
        const process = processesById[processId];
        if (!process) {
            return {};
        }
        return { process };
    },
);

export const RequestAnotherInterviewConfirmDialogData = createSelector(
    DialogsDataState,
    Processes,
    (dataState, processesById) => {
        const { processId } = dataState[DialogName.RequestAnotherInterviewConfirm] ?? {};
        if (!processId) {
            return {};
        }
        const process = processesById[processId];
        if (!process) {
            return {};
        }
        return { process };
    },
);

export const RecruiterNoShowConfirmDialogData = createSelector(
    DialogsDataState,
    Events,
    Processes,
    (dataState, eventsById, processesById) => {
        const { eventId } = dataState[DialogName.RecruiterNoShowConfirm] ?? {};
        if (!eventId) {
            return {};
        }
        const event = eventsById[eventId];
        if (!event) {
            return {};
        }
        const process = processesById[event.processId];
        if (!process) {
            return {};
        }
        return { process, event };
    },
);

export const CandidateNoShowDialogData = createSelector(
    DialogsDataState,
    Events,
    Processes,
    (dataState, eventsById, processesById) => {
        const { eventId } = dataState[DialogName.CandidateNoShow] ?? {};
        if (!eventId) {
            return {};
        }
        const event = eventsById[eventId];
        if (!event) {
            return {};
        }
        const process = processesById[event.processId];
        if (!process) {
            return {};
        }
        return { process, event };
    },
);
