import { setDialogOpen } from '@/_reducers/dialogs';
import { SubmitEventNoShowParams, submitEventNoShow } from '@/_reducers/events';
import {
    IsRecruiterNoShowConfirmDialogOpen,
    RecruiterNoShowConfirmDialogData,
} from '@/_selectors';
import { ProcessParty } from '@/lib/api/v1';
import { DialogName } from '@/lib/types';
import { getMixpanelCandidateData } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { connect, useSelector } from 'react-redux';

import RecruiterNoShowConfirmDialog from './RecruiterNoShowConfirmDialog';

function RecruiterNoShowConfirmDialogProvider({
    setDialogOpenAction,
    submitEventNoShow,
}: {
    setDialogOpenAction: (dialog: DialogName, open: boolean) => void;
    submitEventNoShow: (params: SubmitEventNoShowParams) => Promise<void>;
}) {
    const shouldBeOpen = useSelector(IsRecruiterNoShowConfirmDialogOpen);
    const { process, event } = useSelector(RecruiterNoShowConfirmDialogData);

    const hasData = !!process && !!event;
    const isOpen = hasData && shouldBeOpen;

    const setOpen = (open: boolean) => {
        setDialogOpenAction(DialogName.RecruiterNoShowConfirm, open);
    };

    const handleClose = () => setOpen(false);

    const handleConfirm = async () => {
        trackEvent(MixpanelEvent.ProcessActionsClickRecruiterNoShow, {
            ...getMixpanelCandidateData(process!),
            event_type: event!.eventType,
        });
        await submitEventNoShow({
            eventId: event!.id,
            noShowBy: ProcessParty.Recruiter,
        });
    };

    return (
        <>
            {hasData && (
                <RecruiterNoShowConfirmDialog
                    isOpen={isOpen}
                    setOpen={setOpen}
                    handleClose={handleClose}
                    process={process}
                    event={event}
                    onConfirm={handleConfirm}
                />
            )}
        </>
    );
}

const mapDispatchToProps = {
    setDialogOpenAction: setDialogOpen,
    submitEventNoShow,
};

export default connect(null, mapDispatchToProps)(RecruiterNoShowConfirmDialogProvider);
