import { ProcessStatus, ProcessSubstatus } from '@/lib/api/v1';
import { WithRequired } from '@/lib/base';

import { CandidateEvent, Process } from './apiModels';

export interface ProcessWithRelations extends Omit<Process, 'relevantEvent'> {
    relevantEvent?: CandidateEvent;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PatchProcessAction = (processId: string) => Promise<any>;

export type ProcessNextStep =
    | ProcessStatus.PhoneInterview
    | ProcessStatus.InPersonInterview
    | ProcessStatus.Paperwork
    | ProcessStatus.Orientation
    | ProcessStatus.Hired;

export enum InProcessFilter {
    All = 'all',
    PendingAction = 'pendingAction',
    Upcoming = 'upcoming',
    InProgress = 'inProgress',
    Paperwork = 'paperwork',
    InterviewRequest = 'interviewRequest',
}

export type ProcessPendingEventFeedback = WithRequired<Process, 'relevantEvent'> & {
    status: EventRelatedProcessStatus;
    substatus: ProcessSubstatus.Done;
};

export type EventRelatedProcessStatus =
    | ProcessStatus.PhoneInterview
    | ProcessStatus.InPersonInterview
    | ProcessStatus.Orientation;

export const eventableProcessStatuses = [
    ProcessStatus.PhoneInterview,
    ProcessStatus.InPersonInterview,
    ProcessStatus.Orientation,
];

export enum ProcessActionButton {
    MarkHired = 'markHired',
    MoveToOffer = 'moveToOffer',
    RequestAnotherInterview = 'requestAnotherInterview',
    Reschedule = 'reschedule',
    Terminate = 'terminate',
    RecruiterNoShow = 'recruiterNoShow',
    CandidateNoShow = 'candidateNoShow',
}
