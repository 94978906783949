import { NotificationPing } from '@/components/ui';
import { cn } from '@/lib/utils';

import { NavItemMobileProps } from './types';

const TRANSITION_CLASS = 'transition-colors duration-200 ease-in-out';

function NavItemMobile({ selected, Icon, action, hasNotification }: NavItemMobileProps) {
    const showNotificaitonPing = hasNotification && !selected;
    return (
        <div
            className={cn('relative flex-grow p-4 bg-white', TRANSITION_CLASS, {
                'hover:bg-accent100 cursor-pointer': !selected,
                'border-b-[3px] border-black ': selected,
            })}
            onClick={action}
        >
            <div
                className={cn(
                    'relative w-full flex items-center justify-center rounded-xl px-6 py-4',
                )}
            >
                <div className={cn('relative h-6 w-6')}>
                    {showNotificaitonPing && (
                        <NotificationPing
                            className={cn('absolute -left-1.5 -top-1.5')}
                        />
                    )}
                    <Icon className={cn('stroke-black fill-black h-full w-full')} />
                </div>
            </div>
        </div>
    );
}

export default NavItemMobile;
