import { DivAttributes } from '@/lib/base';
import { cn } from '@/lib/utils';

const NotificationPing = ({ ...props }: DivAttributes) => {
    return (
        <div {...props} className={cn('relative flex h-3 w-3', props?.className)}>
            <div
                className={cn(
                    'absolute inline-flex h-full w-full rounded-full bg-red100 opacity-75',
                    'animate-ping will-change-transform-opacity',
                )}
            />
            <div className={cn('relative inline-flex rounded-full h-3 w-3 bg-red100')} />
        </div>
    );
};
export default NotificationPing;
