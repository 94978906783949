import { ExpandIcon, LocationIcon, ProfessionIcon, TimeIcon } from '@/components/svgs';
import { ProfessionAvatar } from '@/components/ui';
import useOpenDialog from '@/hooks/useOpenDialog';
import { DialogName, Process, Profession } from '@/lib/types';
import { cn, getFacilityNameAndCount } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import NewProcessActionsViewProvider from '@/processes/actions/newProcesses/NewProcessActionsViewProvider';
import { candidates as strings } from '@/strings';

function NewCandidateBlockMobile({
    process,
    className,
}: {
    process: Process;
    className?: string;
}) {
    const { openDialog } = useOpenDialog(DialogName.CandidateDetails);
    const onExpand = () => {
        trackEvent(MixpanelEvent.ExpandNewCandidate, {
            candidate_id: process.candidate.id,
            candidate_name: process.candidate.name,
            via: 'new_candidate_block',
        });
        openDialog({ processId: process.id });
    };
    const { candidate } = process;
    const {
        name,
        yearsOfExperience,
        profession: professionAsString,
        shifts,
    } = candidate;
    const profession = professionAsString as Profession;
    const { facilitiesCount, facilityName } = getFacilityNameAndCount(process);

    return (
        <div
            className={cn(
                'bg-white p-6 relative max-w-full w-[420px] rounded-2xl cursor-pointer',
                className,
            )}
            onClick={onExpand}
        >
            <div className={cn('w-full flex justify-between items-center')}>
                <div className={cn('flex items-center gap-3')}>
                    <ProfessionAvatar
                        className={cn('w-12 h-12')}
                        profession={profession}
                    />
                    <div className={cn('text-15 font-bold')}>{name}</div>
                </div>
                <div
                    className={cn(
                        'bg-accent300 rounded-full h-12 w-12',
                        'flex justify-center items-center',
                    )}
                >
                    <ExpandIcon />
                </div>
            </div>
            <div className={cn('py-6 flex flex-col justify-start gap-3')}>
                <div className={cn('flex items-center gap-2')}>
                    <ProfessionIcon className={cn('w-4 h-4')} />
                    <div className={cn('text-12 font-semibold')}>
                        {strings.newRequests.candidatesTable.cellRole.func(
                            profession,
                            yearsOfExperience,
                        )}
                    </div>
                </div>
                <div className={cn('flex items-center gap-2')}>
                    <TimeIcon className={cn('w-4 h-4')} />
                    <div className={cn('text-12 font-semibold')}>
                        {strings.newRequests.candidatesTable.cellShifts.func(
                            shifts ?? undefined,
                        )}
                    </div>
                </div>
                <div className={cn('flex items-center gap-2')}>
                    <LocationIcon className={cn('w-4 h-4')} />
                    <div className={cn('text-12 font-semibold')}>
                        {strings.newRequests.candidatesTable.cellFacilities.func(
                            facilityName,
                            facilitiesCount,
                        )}
                    </div>
                </div>
            </div>
            <div className={cn('mt-1 w-full')}>
                <NewProcessActionsViewProvider
                    process={process}
                    rejectButtonProps={{ className: cn('w-full') }}
                    approveButtonProps={{ className: cn('w-full') }}
                />
            </div>
        </div>
    );
}

export default NewCandidateBlockMobile;
