import { Process } from '@/lib/types';
import { getFirstName } from '@/lib/utils';
import { dialogs as dialogsStrings } from '@/strings';

import CandidateTitle from '../common/CandidateTitle';
import ConfirmationDialog from '../confirmation/ConfirmationDialog';
import { BaseDialogPropsCommon } from '../types';

const strings = dialogsStrings.requestAnotherInterviewConfirm;

interface Props extends BaseDialogPropsCommon {
    process: Process;
    onConfirm: () => Promise<void>;
}

function RequestAnotherInterviewConfirmDialog({
    process,
    onConfirm,
    ...dialogProps
}: Props) {
    const firstName = getFirstName(process.candidate);
    return (
        <ConfirmationDialog
            {...dialogProps}
            header={<CandidateTitle process={process} />}
            content={{
                title: strings.title,
                description: strings.description.func(firstName),
                cancel: {
                    label: strings.cancelButton,
                    action: dialogProps.handleClose,
                },
                confirm: {
                    label: strings.confirmButton,
                    action: onConfirm,
                    failureTitle: strings.submitFailure.failureTitle,
                },
            }}
            successContent={{
                title: strings.successStep.title.func(firstName),
                description: strings.successStep.description.func(firstName),
            }}
        />
    );
}

export default RequestAnotherInterviewConfirmDialog;
