import { DialogName } from '@/lib/types';

import CandidateDetailsDialogProvider from './candidateDetails/CandidateDetailsDialogProvider';
import CandidateNoShowDialogProvider from './candidateNoShow/CandidateNoShowDialogProvider';
import DialogsContextProvider from './dialogsContext';
import EventDetailsDialogProvider from './eventDetails/EventDetailsDialogProvider';
import MarkHiredConfirmDialogProvider from './markHiredConfirm/MarkHiredConfirmDialogProvider';
import MoveToOfferConfirmDialogProvider from './moveToOfferConfirm/moveToOfferConfirmDialogProvider';
import AvailabilityFormDialogProvider from './recruiterAvailability/AvailabilityFormDialogProvider';
import RecruiterNoShowConfirmDialogProvider from './recruiterNoShowConfirm/RecruiterNoShowConfirmDialogProvider';
import RejectCandidateDialogProvider from './rejectCandidate/RejectCandidateDialogProvider';
import RequestAnotherInterviewConfirmDialogProvider from './requestAnotherInterviewConfirm/RequestAnotherInterviewConfirmDialogProvider';
import RescheduleEventConfirmDialogProvider from './reschduleEventConfirm/RescheduleEventConfirmDialogProvider';
import TerminateProcessDialogProvider from './terminateProcess/TerminateProcessDialogProvider';
import WeeklyAvMissingDialogProvider from './weeklyAvailabilityMissing/WeeklyAvMissingDialogProvider';

const dialogsOrder = [
    DialogName.RecruiterAvailability,
    DialogName.CandidateDetails,
    DialogName.WeeklyAvailabilityMissing,
    DialogName.RescheduleEventConfirm,
    DialogName.RejectCandidateConfirm,
    DialogName.TerminateProcess,
    DialogName.EventDetails,
    DialogName.MoveToOfferConfirm,
    DialogName.MarkHiredConfirm,
    DialogName.RequestAnotherInterviewConfirm,
    DialogName.RecruiterNoShowConfirm,
    DialogName.CandidateNoShow,
];

function DialogsProvider({ children }: { children: React.ReactNode }) {
    return (
        <DialogsContextProvider>
            {dialogsOrder.map((dialog) => (
                <div key={dialog}>
                    {dialog === DialogName.CandidateNoShow && (
                        <CandidateNoShowDialogProvider />
                    )}
                    {dialog === DialogName.RecruiterNoShowConfirm && (
                        <RecruiterNoShowConfirmDialogProvider />
                    )}
                    {dialog === DialogName.RequestAnotherInterviewConfirm && (
                        <RequestAnotherInterviewConfirmDialogProvider />
                    )}
                    {dialog === DialogName.MarkHiredConfirm && (
                        <MarkHiredConfirmDialogProvider />
                    )}
                    {dialog === DialogName.RecruiterAvailability && (
                        <AvailabilityFormDialogProvider />
                    )}
                    {dialog === DialogName.RejectCandidateConfirm && (
                        <RejectCandidateDialogProvider />
                    )}
                    {dialog === DialogName.WeeklyAvailabilityMissing && (
                        <WeeklyAvMissingDialogProvider />
                    )}
                    {dialog === DialogName.RescheduleEventConfirm && (
                        <RescheduleEventConfirmDialogProvider />
                    )}
                    {dialog === DialogName.TerminateProcess && (
                        <TerminateProcessDialogProvider />
                    )}
                    {dialog === DialogName.EventDetails && (
                        <EventDetailsDialogProvider />
                    )}
                    {dialog === DialogName.CandidateDetails && (
                        <CandidateDetailsDialogProvider />
                    )}
                    {dialog === DialogName.MoveToOfferConfirm && (
                        <MoveToOfferConfirmDialogProvider />
                    )}
                </div>
            ))}
            {children}
        </DialogsContextProvider>
    );
}

export default DialogsProvider;
