import Logger from '@/_helpers/logger';

export function getAllEnumKeys<T extends object>(enumObj: T) {
    return Object.keys(enumObj)
        .filter((key) => {
            return isNaN(Number(key));
        })
        .map((key) => key as keyof T);
}

export function getAllEnumValues<T extends object>(enumObj: T): T[keyof T][] {
    return getAllEnumKeys(enumObj).map((key) => enumObj[key]);
}

export const sortEnumList = <T>(list: T[], orderMap: T[]): T[] => {
    return [...list].sort((a, b) => {
        const indexA = orderMap.indexOf(a);
        const indexB = orderMap.indexOf(b);
        return indexA - indexB;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractMsgFromCatchedError(error: any, withStackTrace = false): string {
    if (error instanceof Error) {
        let res = `${error.name}: ${error.message}`;
        if (withStackTrace) {
            res += `\n${error.stack || 'No stack trace available'}`;
        }
        return res;
    }
    if (typeof error === 'object') {
        try {
            return JSON.stringify(error);
        } catch {
            Logger.error(
                `Could not stringify error object due to circular references. ${error.name}`,
            );
            return 'Unknown Error';
        }
    }
    return String(error);
}
