import useOpenDialog from '@/hooks/useOpenDialog';
import { ProcessAction, ProcessStatus } from '@/lib/api/v1';
import { getAllEnumValues } from '@/lib/base';
import { DialogName, Process, ProcessActionButton } from '@/lib/types';
import { getMixpanelCandidateData, valuesOf } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { mapValues, pick } from 'lodash';

import { ProcessActionMenuItem } from './types';
import { getLabelAndIcon, getSortedProcessActionItems } from './utils';

const isActionButtonAvailableChecks: Record<
    ProcessActionButton,
    (process: Process) => boolean
> = {
    [ProcessActionButton.MoveToOffer]: (p) =>
        p.actions.includes(ProcessAction.MoveToPaperwork) &&
        [ProcessStatus.InPersonInterview, ProcessStatus.PhoneInterview].includes(
            p.status,
        ),
    [ProcessActionButton.MarkHired]: (p) =>
        p.actions.includes(ProcessAction.MarkSuccess),
    [ProcessActionButton.RequestAnotherInterview]: (p) =>
        p.actions.includes(ProcessAction.MoveToInPersonInterview) &&
        [ProcessStatus.InPersonInterview, ProcessStatus.PhoneInterview].includes(
            p.status,
        ),
    [ProcessActionButton.Reschedule]: (p) => {
        return (
            p.actions.includes(ProcessAction.CancelEvent) &&
            !!p.relevantEvent &&
            p.relevantEvent.startAt > new Date()
        );
    },
    [ProcessActionButton.Terminate]: (p) =>
        p.actions.includes(ProcessAction.TerminateProcess),
    [ProcessActionButton.RecruiterNoShow]: (p) =>
        p.actions.includes(ProcessAction.MarkEventUnoccurred),
    [ProcessActionButton.CandidateNoShow]: (p) =>
        p.actions.includes(ProcessAction.MarkEventUnoccurred),
};

const processActionButtons = getAllEnumValues(ProcessActionButton);

function useAvailableProcessActionButtons(process: Process): ProcessActionButton[] {
    return processActionButtons.filter((pab) =>
        isActionButtonAvailableChecks[pab](process),
    );
}

export function useProcessActionMenuItems(process: Process): ProcessActionMenuItem[] {
    const available = useAvailableProcessActionButtons(process);
    const { openDialog: terminate } = useOpenDialog(DialogName.TerminateProcess);
    const { openDialog: reschedule } = useOpenDialog(DialogName.RescheduleEventConfirm);
    const { openDialog: moveToOffer } = useOpenDialog(DialogName.MoveToOfferConfirm);
    const { openDialog: markHired } = useOpenDialog(DialogName.MarkHiredConfirm);
    const { openDialog: requestInPersonInterview } = useOpenDialog(
        DialogName.RequestAnotherInterviewConfirm,
    );
    const { openDialog: recruiterNoShow } = useOpenDialog(
        DialogName.RecruiterNoShowConfirm,
    );
    const { openDialog: candidateNoShow } = useOpenDialog(DialogName.CandidateNoShow);

    const actionsPerButton: Record<ProcessActionButton, () => void> = {
        moveToOffer: () => {
            trackEvent(
                MixpanelEvent.ProcessActionsClickMoveToOffer,
                getMixpanelCandidateData(process),
            );
            moveToOffer({ processId: process.id });
        },
        requestAnotherInterview: () => {
            trackEvent(
                MixpanelEvent.ProcessActionsClickRequestAnotherInterview,
                getMixpanelCandidateData(process),
            );
            requestInPersonInterview({ processId: process.id });
        },
        markHired: () => {
            trackEvent(
                MixpanelEvent.ProcessActionsClickOrientationDone,
                getMixpanelCandidateData(process),
            );
            markHired({ processId: process.id });
        },
        reschedule: () => {
            const nextEventId = process.relevantEvent?.id;
            trackEvent(MixpanelEvent.ProcessActionsRescheduleEvent);
            nextEventId && reschedule({ eventId: nextEventId });
        },
        terminate: () => {
            trackEvent(MixpanelEvent.ProcessActionsTerminateCandidate);
            terminate({ processId: process.id });
        },
        candidateNoShow: () => {
            const doneEventId = process.relevantEvent?.id;
            trackEvent(MixpanelEvent.ProcessActionsClickCandidateNoShow, {
                ...getMixpanelCandidateData(process),
                event_type: process.relevantEvent!.eventType,
            });
            doneEventId && candidateNoShow({ eventId: doneEventId });
        },
        recruiterNoShow: () => {
            const doneEventId = process.relevantEvent?.id;
            trackEvent(MixpanelEvent.ProcessActionsClickRecruiterNoShow, {
                ...getMixpanelCandidateData(process),
                event_type: process.relevantEvent!.eventType,
            });
            doneEventId && recruiterNoShow({ eventId: doneEventId });
        },
    };

    return getSortedProcessActionItems(
        valuesOf(
            mapValues(pick(actionsPerButton, available), (action, id) => ({
                id: id as ProcessActionButton,
                content: getLabelAndIcon(id as ProcessActionButton, process),
                onClick: action,
            })),
        ),
    );
}

export default useProcessActionMenuItems;
