import { fetchProcessesPendingFeedback } from '@/_reducers/processes';
import { PendingFeedbackProcesses, PendingTasksFetcherData } from '@/_selectors';
import useFetcherBase from '@/fetchers/useFetcherBase';
import useMissingEventsFetcher from '@/hooks/useMissingEventsFetcher';
import { valuesOf } from '@/lib/utils';
import { sortBy } from 'lodash';

function usePendingTasks() {
    const {
        data: pendingFeedbackProcessesById,
        refresh,
        initiated: initiatedPendingTasks,
        isLoading: isLoadingPendingTasks,
    } = useFetcherBase({
        fetchFunc: fetchProcessesPendingFeedback,
        dataSelector: PendingFeedbackProcesses,
        fetcherSelector: PendingTasksFetcherData,
    });
    const {
        isLoading: isLoadingEvents,
        isMissingEvents,
        didInitialFetch,
    } = useMissingEventsFetcher();

    const isLoadingTasks = isLoadingPendingTasks || isLoadingEvents;
    const initiatedTasks =
        initiatedPendingTasks && (!isMissingEvents || didInitialFetch);
    const processes = sortBy(
        valuesOf(pendingFeedbackProcessesById),
        (process) => -process.relevantEvent.endAt,
    );
    return {
        isLoadingTasks,
        initiatedTasks,
        processes,
        refresh,
    };
}

export default usePendingTasks;
