import { DialogContentWithFooter } from '@/components/ui';
import ProcessCardActionsView from '@/dialogs/candidateDetails/ProcessCardActionsView';
import { useIsMediumOrLessScreen } from '@/hooks/useMediaQueries';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';

import ProcessDetails from './ProcessDetails';
import ProcessDetailsMobile from './ProcessDetailsMobile';

interface Props {
    process: Process;
    handleClose: () => void;
}

export function CandidateDetailsDialogContent({ process }: Props) {
    const showMobileView = useIsMediumOrLessScreen();
    return (
        <DialogContentWithFooter
            footer={<ProcessCardActionsView process={process} />}
            footerProps={{ className: cn('p-0') }}
            bodyProps={{ className: cn('md_mn:p-0 bg-accent500') }}
        >
            {showMobileView && <ProcessDetailsMobile process={process} />}
            {!showMobileView && (
                <div className={cn('w-full p-4')}>
                    <ProcessDetails process={process} />
                </div>
            )}
        </DialogContentWithFooter>
    );
}

export default CandidateDetailsDialogContent;
